import React from 'react'
import { kebabCase } from 'lodash'
import Container from "@material-ui/core/Container";
import Cards from "../../components/Cards";
import SEO from "../../components/SEO";
import HeaderSection from "../../components/HeaderSection"; import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
  },
}));
const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location
}) => {
  const classes = useStyles();

  return (
    <Layout location={location}>
      <SEO
        title={"Tags"}
        location={location}
      />
      <Container maxWidth="lg" className={classes.root}>
        <HeaderSection header="Browse all tags" />
        <ul className="taglist">
          {group.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Container>

    </Layout>
  )
}

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
